<template>
  <div class="w-full">
    <BackIcon class="back" @back="backFn" />
    <EnshrineSettingTab :list="tabList" @tabClick="tabClick" />
    <div class="offerings">
      <div class="f fbt">
        <div
          :class="['offer', offerActive === type ? 'offer-active' : '']"
          v-for="{ title, type } in offerings"
          :key="type"
          @click="offerClick(type)"
        >
          {{ title }}
        </div>
      </div>
      <div v-for="({ type, list }, index) in offerings" :key="type" v-show="offerActive === type">
        <div class="offer-list">
          <div class="offer-item" v-for="{ id, name, image } in list" :key="id">
            <div class="offer-item-img f fvc">
              <img :src="image" alt="" srcset="" />
            </div>
            <div class="offer-item-name fvc" @click="offerItemClick(type, { id, image })">
              <van-icon v-if="checkedList[index] && checkedList[index].id === id" name="checked" color="#f87a1b" />
              <van-icon v-else name="circle" />
              <span class="name"> {{ name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="default-offers">
        <p class="title">默认贡品</p>
        <div class="default-offers-list">
          <template v-for="(item, index) in checkedList">
            <div
              :key="index"
              :class="['default-offers-item', 'f', 'fvc']"
              :style="{
                backgroundImage: `url(${
                  item.id
                    ? 'https://img-fe.tengzhihh.com/image/7f64b9987dad1065dbb9396674b53000-136x190.png'
                    : 'https://img-fe.tengzhihh.com/image/9dcceb4b4d08ea62ba10634a85f34ce9-136x190.png'
                })`,
              }"
            >
              <img :src="item.image || item.defaultBg" alt="" srcset="" />
            </div>
          </template>
        </div>
        <p v-if="checkedOfferings.length === offerings.length" class="tips tips-all">一键供奉不会替换正在供奉的贡品</p>
        <p v-else class="tips">一键供奉不会替换正在供奉的贡品</p>
        <van-button
          block
          color="#F87A1B"
          :disabled="checkedOfferings.length !== offerings.length"
          size="small"
          v-throttle="submit"
        >
          确定
        </van-button>
      </div>
    </div>
    <Dialog ref="dialog" />
  </div>
</template>

<script>
import EnshrineSettingTab from '../comp/EnshrineSettingTab';
import BackIcon from '@/components/BackIcon';
import Dialog from '@/components/Dialog/Dialog.vue';
import { getQuickOffering, setQuickOffering } from '@/api/god';
import { commonOperate } from '@/mixins/commonOperate';
const defaultBg = [
  '',
  'https://img-fe.tengzhihh.com/image/0863d05ed1dd7da130c709f0de0506f5-90x170.png',
  'https://img-fe.tengzhihh.com/image/91bad32111e01d162192bd18b93bf049-90x170.png',
  'https://img-fe.tengzhihh.com/image/e6e662aa9d2f2700ba74c1c653fe6953-90x170.png',
  'https://img-fe.tengzhihh.com/image/f29e47e76cd99312a61714944d225bf4-90x170.png',
];
export default {
  components: {
    EnshrineSettingTab,
    BackIcon,
    Dialog,
  },
  mixins: [commonOperate],
  data() {
    return {
      offerings: [],
      offerActive: 1,
      checkedList: [],
      next: null,
      showBackCb: true,
      god_type: 1, //1=神佛, 2= 土地神, 3=祖宗牌
    };
  },
  computed: {
    tabList: function () {
      return [
        { name: 'myGodList', label: '神明管理', god_type: [1, 2] },
        { name: 'deceasedList', label: '逝者管理', god_type: [3] },
        { name: 'enshrineSetting', label: '一键供奉', god_type: [1, 2, 3] },
      ].filter(x => x.god_type.includes(+this.routeQuery.god_type));
    },
    checkedOfferings: function () {
      return this.checkedList.map(x => x.id).filter(x => x);
    },
  },
  created() {
    this.active = this.$route.name;
    this.getQuickOfferingSet();
  },
  methods: {
    submit() {
      this.setQuickOffering(this.checkedOfferings);
    },
    offerItemClick(type, { id, image }) {
      this.checkedList = this.checkedList.map((x, i) => {
        if (this.offerings[i].type === type) {
          this.checkedOfferings.length !== this.checkedList.length &&
            i + 1 !== this.offerings.length &&
            (this.offerActive = this.offerings[i + 1].type);
          return { id, image };
        }
        return x;
      });
    },
    async setQuickOffering(offerings) {
      const { code } = await setQuickOffering({
        serial_id: this.routeQuery.serial_id,
        god_type: this.routeQuery.god_type,
        offerings: JSON.stringify(offerings),
      });
      if (code === 0) {
        this.$track('H5_gongfeng_one_click_setting_success_all');
        return this.$toast('更新成功');
      }
    },
    backCb() {
      const text = this.offerings.map(x => x.title).join('、');
      this.$refs.dialog.initDialog({
        title: '温馨提示',
        content: `你当前还没有完成设置“一键供奉”的操作。需要完成选“${text}”全部流程即结束设置。`,
        confirmText: '继续',
        cancelText: '退出',
        confirmCallback: () => {
          this.$refs.dialog.closeDialog();
        },
        cancelCallback: () => {
          this.next();
        },
      });
    },
    offerClick(offer) {
      this.offerActive = offer;
    },
    async getQuickOfferingSet() {
      const res = await getQuickOffering({
        serial_id: this.routeQuery.serial_id,
        god_type: this.routeQuery.god_type || this.god_type,
      });
      this.offerings = res.data.offerings;
      this.checkedList = res.data.offerings.map(x => {
        return {
          defaultBg: defaultBg[x.type],
        };
      });
      res.data.list.length && (this.checkedList = [].concat(res.data.list));
    },
    tabClick({ name }) {
      this.$router.replace({
        name,
        query: this.$route.query,
      });
    },
    backFn() {
      this.$router.replace({
        path: this.routeQuery.god_type == 3 ? '/deceased/enshrineList' : '/god/index',
        query: this.$route.query,
      });
    },
    handleMsg(msg) {
      if (msg.data.operation === 'indexModeRefresh') {
        this.showBackCb = false;
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    const offerings = this.checkedList.map(x => x.id).filter(x => x);
    if (this.showBackCb && offerings.length !== this.offerings.length) {
      this.next = next;
      this.backCb();
    } else {
      next();
    }
  },
};
</script>

<style lang="less" scoped>
.back {
  position: absolute;
  top: 10px;
  left: 10px;
}
.offerings {
  padding: 10px 15px;
  .offer {
    width: 74px;
    height: 28px;
    line-height: 28px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.45);
    background: #f6f6f6;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #f6f6f6;
  }
  .offer-active {
    background: #ffebd2;
    color: #f87a1b;
    border: 1px solid #f87a1b;
  }
  .offer-list {
    padding: 16px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    min-height: 328px;
    .offer-item {
      border-radius: 10px 10px;
      .offer-item-img {
        width: 108px;
        height: 135px;
        background: url('https://img-fe.tengzhihh.com/image/c925ee96dd3b024a3a381e76b377f0bb-216x270.png') no-repeat
          100%;
        border-radius: 10px 10px 0px 0px;
        img {
          width: 72px;
        }
      }
      .offer-item-name {
        height: 24px;
        line-height: 24px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        margin: 0 auto;
        border-radius: 0 0 10px 10px;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
        .name {
          margin: 0 20px 0 10px;
        }
      }
    }
  }
  .default-offers {
    padding: 15px;
    box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.16);
    border-radius: 15px 15px 15px 15px;
    .van-button {
      border-radius: 10px;
    }
    .title {
      line-height: 24px;
      font-size: 20px;
    }
    .tips {
      line-height: 20px;
      font-size: 16px;
      margin-bottom: 16px;
      text-align: center;
    }
    .tips-all {
      color: #f87a1b;
    }
    .default-offers-list {
      display: flex;
      justify-content: space-between;
      padding: 16px 0;
      .default-offers-item {
        width: 68px;
        height: 95px;
        flex: 1;
        margin-right: 8px;
        background-size: 100% 100%;
        img {
          display: flex;
          width: 45px;
          height: 85px;
        }
      }
      .default-offers-item:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
