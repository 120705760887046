import { request } from '@/utils/request';

export function setRemindDate(data) {
  return request({
    url: '/user/festival/remind',
    method: 'post',
    data,
  });
}
export function getRemindDate(params) {
  return request({
    url: '/user/festival/remind',
    method: 'get',
    params,
  });
}
export function getLately(params) {
  return request({
    url: '/festival/lately',
    method: 'get',
    params,
  });
}
export function musicReport(params) {
  return request({
    url: '/report-data',
    method: 'post',
    params,
  });
}
