var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.changeState === 1
    ? _c(
        "div",
        { staticClass: "f fv fvc" },
        [
          _c("div", { staticClass: "tip" }, [
            _vm._v(
              "当前手机号：" +
                _vm._s(`${_vm.userInfo.phone_code} ${_vm.userInfo.phone}`)
            ),
          ]),
          _c("van-field", {
            attrs: {
              center: "",
              clearable: "",
              "max-length": "6",
              placeholder: "请输入短信验证码",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "button",
                  fn: function () {
                    return [
                      _c(
                        "van-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.getCode },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.codeSentStatus ? _vm.calcTime : "发送验证码"
                            )
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2737988783
            ),
            model: {
              value: _vm.code,
              callback: function ($$v) {
                _vm.code = $$v
              },
              expression: "code",
            },
          }),
          _c(
            "van-button",
            {
              staticClass: "next-btn",
              attrs: { loading: _vm.loading, type: "info" },
              on: { click: _vm.verifyCode },
            },
            [_vm._v("下一步")]
          ),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "f fv fvc" },
        [
          _c("van-field", {
            attrs: {
              clearable: "",
              center: "",
              placeholder: "请输入新的电话号码",
              maxlength: "11",
            },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "f fc" },
                      [
                        _c(
                          "van-button",
                          {
                            staticClass: "area-btn",
                            attrs: { size: "small" },
                            on: {
                              click: function ($event) {
                                _vm.areaPicker = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.areaText || "选择地区") + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "van-popup",
                      {
                        attrs: { position: "bottom" },
                        model: {
                          value: _vm.areaPicker,
                          callback: function ($$v) {
                            _vm.areaPicker = $$v
                          },
                          expression: "areaPicker",
                        },
                      },
                      [
                        _c("van-picker", {
                          attrs: {
                            "show-toolbar": "",
                            columns: _vm.areaColumns,
                          },
                          on: {
                            confirm: _vm.onConfirm,
                            cancel: function ($event) {
                              _vm.areaPicker = false
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.phone,
              callback: function ($$v) {
                _vm.phone = $$v
              },
              expression: "phone",
            },
          }),
          _c("van-field", {
            attrs: {
              center: "",
              clearable: "",
              placeholder: "请输入短信验证码",
            },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function () {
                  return [
                    _c(
                      "van-button",
                      { attrs: { size: "small" }, on: { click: _vm.getCode } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.codeSentStatus ? _vm.calcTime : "发送验证码"
                          )
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.code,
              callback: function ($$v) {
                _vm.code = $$v
              },
              expression: "code",
            },
          }),
          _c(
            "van-button",
            {
              staticClass: "next-btn",
              attrs: { loading: _vm.loading, type: "info" },
              on: { click: _vm.checkPhone },
            },
            [_vm._v("下一步")]
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }