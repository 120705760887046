<template>
  <div class="xiangxun-gift-container">
    <div class="title">{{ giftInfo.title }}</div>
    <img v-if="giftInfo.gift" class="gift-img" :src="giftInfo.gift.img" alt="" />
    <div class="time">{{ giftInfo.can_receive_num }}&nbsp;次</div>
    <div :class="['btn', { disabled: giftInfo.can_receive_num == 0 }]" @click="handleClick">兑换</div>
    <div class="sub-title">兑换历史</div>

    <div class="history">
      <template v-if="giftInfo.list">
        <div v-if="giftInfo.list.length == 0" class="empty">暂无兑换记录</div>
        <div v-for="item in giftInfo.list" class="history-item" @click="handleItemClick(item)">
          <div class="user-info">
            <span class="name">{{ item.user_name }}</span>
            {{ item.phone }}
          </div>
          <div class="address">{{ item.address }}</div>
          <div class="gift-info">兑换产品：{{ item.gift_name }}×{{ item.num }}</div>
        </div>
      </template>
    </div>

    <PopupForm :info="giftInfo" ref="form" @submit="getGift" />
    <PopupDetails ref="details" />
  </div>
</template>
<script>
import { Toast } from 'vant';
import apiPath from '@/utils/apiPath';

import PopupForm from './comp/PopupForm.vue';
import PopupDetails from './comp/PopupDetails.vue';

export default {
  components: { PopupForm, PopupDetails },
  data() {
    return {
      giftInfo: {},
    };
  },
  mounted() {
    this.getHistory();
  },
  methods: {
    getHistory() {
      if (this.$route.query.id) {
        this.$http.get(`${apiPath.getXiangxunGift}/${this.$route.query.id}`).then(res => {
          this.giftInfo = res.data;
        });
      }
    },
    getGift(data) {
      Toast({
        duration: 6000,
        message: '获取中...',
        forbidClick: false,
      });
      this.$http
        .post(`${apiPath.getXiangxunGiftV2}/${this.$route.query.id}`, {
          address_id: data.address_id,
          num: data.num,
        })
        .then(res => {
          this.$toast('兑换成功');
          window.location.reload();
        });
    },
    handleClick() {
      if (!this.giftInfo.can_receive_num || this.giftInfo.can_receive_num == 0) {
        return;
      }
      this.$refs.form.open();
    },
    handleItemClick(data) {
      this.$refs.details.open(data);
    },
  },
};
</script>
<style lang="less" scoped>
.xiangxun-gift-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 10px;
  box-sizing: border-box;
  background: linear-gradient(358deg, #f5f5f5ff 75%, #ffead9 100%);
  padding-top: 40px;

  .title {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 20px;
  }
  .gift-img {
    width: 89px;
    margin-bottom: 18px;
  }
  .time {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 16px;
    color: #f87a1b;
    margin-bottom: 8px;
  }
  .btn {
    text-align: center;
    width: 179px;
    height: 40px;
    background: #ff8e38;
    border-radius: 10px;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    color: #ffffff;
    margin-bottom: 52px;

    &.disabled {
      background-color: #aaa;
    }
  }
  .sub-title {
    width: 345px;
    display: flex;
    align-items: center;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);

    &::before {
      display: inline-block;
      content: '';
      width: 4px;
      height: 14px;
      background: #ff8e38;
      border-radius: 2px;
      margin-right: 8px;
    }
  }

  .history {
    padding-top: 20px;
    .empty {
      margin: 20px auto;
      font-weight: 400;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.65);
    }

    .history-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 355px;
      height: 102px;
      background: #ffffff;
      border-radius: 10px;
      padding: 16px;
      box-sizing: border-box;

      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);

      .name {
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 500;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        margin-right: 10px;
      }
    }
  }
}
</style>
