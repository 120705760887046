import Layout from '@/components/Layout/Layout';

export default [
  {
    meta: {
      title: '首页',
    },
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'index',
        meta: {
          title: '首页',
          showBottom: true,
          trackName: 'enter_shouye_tab',
        },
        component: () => import('@/pages/device/list.vue'),
      },
    ],
  },

  {
    meta: {
      title: '我的',
    },
    path: '/my',
    component: Layout,
    children: [
      {
        path: 'wallet',
        meta: {
          title: '我的钱包',
        },
        component: () => import('@/pages/my/wallet.vue'),
      },

      {
        path: 'index',
        meta: {
          title: '我的',
          showBottom: true,
          trackName: 'enter_mine_tab',
        },
        component: () => import('@/pages/my/index.vue'),
      },
      {
        path: 'info',
        meta: {
          title: '个人资料',
        },
        component: () => import('@/pages/my/info.vue'),
      },
      {
        path: 'changePhone',
        meta: {
          title: '修改手机',
        },
        component: () => import('@/pages/my/changePhone.vue'),
      },

      {
        path: 'instructions',
        meta: {
          title: '操作说明',
        },
        component: () => import('@/pages/my/instructions.vue'),
      },
      {
        path: 'settings',
        meta: {
          title: '个人设置',
        },
        component: () => import('@/pages/my/settings.vue'),
      },
      {
        path: 'about',
        meta: {
          title: '关于我们',
        },
        component: () => import('@/pages/my/about.vue'),
      },

      {
        path: 'userAgreement',
        meta: {
          title: '用户协议',
        },
        component: () => import('@/pages/my/userAgreement.vue'),
      },
      {
        path: 'privacyPolicy',
        meta: {
          title: '隐私政策',
        },
        component: () => import('@/pages/my/privacyPolicy.vue'),
      },
      {
        path: 'delivery-address',
        name: 'delivery-address',
        meta: {
          title: '我的收货地址',
        },
        component: () => import('@/pages/my/address/deliveryAddress.vue'),
      },
      {
        path: 'add-address',
        name: 'add-address',
        meta: {
          title: '添加收货地址',
        },
        component: () => import('@/pages/my/address/addAddress.vue'),
      },
    ],
  },
  {
    meta: {
      title: '消息中心',
    },
    path: '/msg',
    component: Layout,
    children: [
      {
        path: 'list',
        meta: {
          title: '消息中心',
        },
        component: () => import('@/pages/message/list.vue'),
      },
      {
        path: 'detail',
        meta: {
          title: '消息详情',
        },
        component: () => import('@/pages/message/detail.vue'),
      },
      {
        path: 'settings',
        meta: {
          title: '消息设置',
        },
        component: () => import('@/pages/message/settings.vue'),
      },
      {
        path: 'machineNotify',
        meta: {
          title: '设备消息通知',
        },
        component: () => import('@/pages/message/machineNotify.vue'),
      },
    ],
  },
  {
    meta: {
      title: '设置',
    },
    path: '/settings',
    component: Layout,
    children: [
      {
        path: 'initSelect',
        meta: {
          title: '初始化',
        },
        component: () => import('@/pages/settings/initSelect.vue'),
      },
      {
        path: 'modeSelect',
        meta: {
          title: '模式选择',
        },
        component: () => import('@/pages/settings/modeSelect.vue'),
      },
      {
        path: 'index',
        meta: {
          title: '设置',
        },
        component: () => import('@/pages/settings/index.vue'),
      },
      {
        path: 'client',
        meta: {
          title: '神台机设置',
        },
        component: () => import('@/pages/settings/client.vue'),
      },
      {
        path: 'show',
        meta: {
          title: '显示设置',
        },
        component: () => import('@/pages/settings/show.vue'),
      },
      {
        path: 'sound',
        meta: {
          title: '音效设置',
        },
        component: () => import('@/pages/settings/sound.vue'),
      },
      {
        path: 'aroma',
        meta: {
          title: '香薰设置',
        },
        component: () => import('@/pages/settings/aroma.vue'),
      },
      {
        path: 'capacity',
        meta: {
          title: '香薰容量',
        },
        component: () => import('@/pages/settings/capacity.vue'),
      },
      {
        path: 'timingList',
        meta: {
          title: '定时设置',
        },
        component: () => import('@/pages/settings/timingList.vue'),
      },
      {
        path: 'timingEdit',
        meta: {
          title: '定时编辑',
        },
        component: () => import('@/pages/settings/timingEdit.vue'),
      },
      {
        path: 'voiceControl',
        meta: {
          title: '声控设置',
        },
        component: () => import('@/pages/settings/voiceControl.vue'),
      },
      {
        path: 'account',
        meta: {
          title: '绑定账号管理',
        },
        component: () => import('@/pages/settings/account.vue'),
      },
      {
        path: 'appearanceSetting',
        name: 'appearanceSetting',
        meta: {
          title: '外观设置',
        },
        component: () => import('@/pages/settings/appearance/index.vue'),
      },
      {
        path: 'cabinetAppearance',
        name: 'cabinetAppearance',
        meta: {
          title: '神柜外观',
        },
        component: () => import('@/pages/settings/appearance/cabinetAppearance.vue'),
      },
      {
        path: 'ancestorMemorial',
        name: 'ancestorMemorial',
        meta: {
          title: '逝者牌位',
        },
        component: () => import('@/pages/settings/appearance/ancestorMemorial.vue'),
      },
      {
        path: 'vipTools',
        name: 'vipTools',
        meta: {
          title: 'VIP功能设置',
        },
        component: () => import('@/pages/settings/vipTools.vue'),
      },
    ],
  },
  {
    meta: {
      title: '登录',
    },
    path: '/login',
    component: Layout,
    children: [
      {
        path: 'index',
        meta: {
          title: '登录',
        },
        component: () => import('@/pages/login/index.vue'),
      },
    ],
  },
  {
    meta: {
      title: '绑定',
    },
    path: '/bind',
    component: Layout,
    children: [
      {
        path: 'index',
        meta: {
          title: '绑定',
        },
        component: () => import('@/pages/bind/index.vue'),
      },

      {
        path: 'success',
        meta: {
          title: '成功',
        },
        component: () => import('@/pages/bind/success.vue'),
      },
    ],
  },
  {
    meta: {
      title: '设备',
    },
    path: '/device',
    component: Layout,
    children: [
      {
        path: 'index',
        name: 'device',
        meta: {
          title: '眷吉',
          trackName: 'enter_shentai_zhuye',
        },
        component: () => import('@/pages/device/index.vue'),
      },
    ],
  },
  {
    meta: {
      title: '家风家训',
    },
    path: '/motto',
    component: Layout,
    children: [
      {
        path: 'list',
        meta: {
          title: '上传家风家训',
        },
        component: () => import('@/pages/familyMotto/list.vue'),
      },
      {
        path: 'uploadIndex',
        meta: {
          title: '上传家风家训',
        },
        component: () => import('@/pages/familyMotto/uploadIndex.vue'),
      },

      {
        path: 'detail',
        meta: {
          title: '详情',
        },
        component: () => import('@/pages/familyMotto/detail.vue'),
      },
    ],
  },
  {
    meta: {
      title: '神佛',
    },
    path: '/god',
    component: Layout,
    children: [
      {
        path: 'index',
        name: 'godIndex',
        meta: {
          title: '详情',
          trackName: 'enter_gongfeng_pages',
        },

        component: () => import('@/pages/god/index.vue'),
      },
      {
        path: 'myGodList',
        name: 'myGodList',
        meta: {
          title: '神明管理',
        },

        component: () => import('@/pages/god/myGodList.vue'),
      },
      {
        path: 'enshrineSetting',
        name: 'enshrineSetting',
        meta: {
          title: '一键供奉',
        },
        component: () => import('@/pages/god/enshrineSetting/index.vue'),
      },
      {
        path: 'woodfish',
        name: 'woodfish',
        meta: {
          title: '修行',
        },
        component: () => import('@/pages/god/woodfish.vue'),
      },
      {
        path: 'woodfishSetting',
        name: 'woodfishSetting',
        meta: {
          title: '修行',
        },
        component: () => import('@/pages/god/woodfishSetting.vue'),
      },
    ],
  },
  {
    meta: {
      title: '功德',
    },
    path: '/fude',
    component: Layout,
    children: [
      {
        path: 'dailyTaskList',
        name: 'dailyTaskList',
        meta: {
          title: '日常任务',
          trackName: 'enter_shentai_zhuye',
        },
        component: () => import('@/pages/fude/task/dailyTaskList.vue'),
      },
      {
        path: 'wishInfoIndex',
        name: 'wishInfoIndex',
        meta: {
          title: '祈愿详情',
          trackName: 'enter_shentai_zhuye',
        },
        component: () => import('@/pages/fude/wish/wishInfoIndex.vue'),
      },
      {
        path: 'wishInfoHandle',
        name: 'wishInfoHandle',
        meta: {
          title: '我的祈愿',
          trackName: 'enter_shentai_zhuye',
        },
        component: () => import('@/pages/fude/wish/wishInfoHandle.vue'),
      },
      {
        path: 'list',
        meta: {
          title: '功德列表',
        },

        component: () => import('@/pages/fude/list.vue'),
      },
      {
        path: 'explanation',
        meta: {
          title: '功德说明',
        },

        component: () => import('@/pages/fude/explanation.vue'),
      },
      {
        path: 'rankList',
        name: 'rankList',
        meta: {
          title: '功德榜',
        },

        component: () => import('@/pages/fude/rank/rankList.vue'),
      },
      {
        path: 'rankDetail',
        name: 'rankDetail',
        meta: {
          title: '功德榜',
        },

        component: () => import('@/pages/fude/rank/rankDetail.vue'),
      },
    ],
  },
  {
    meta: {
      title: '教育',
    },
    path: '/edu',
    component: Layout,
    children: [
      {
        path: 'list',
        meta: {
          title: '文化科普',
          trackName: 'enter_wenhua_pages',
        },
        component: () => import('@/pages/edu/list.vue'),
      },
      {
        path: 'subList',
        meta: {
          title: '子列表',
        },
        component: () => import('@/pages/edu/subList.vue'),
      },
    ],
  },
  {
    meta: {
      title: '音',
    },
    path: '/music',
    component: Layout,
    children: [
      {
        name: 'musicIndex',
        path: 'musicIndex',
        meta: {
          title: '佛音',
        },
        component: () => import('@/pages/music/musicIndex.vue'),
      },
      {
        name: 'lessonList',
        path: 'lessonList',
        meta: {
          title: '功课列表',
        },
        component: () => import('@/pages/music/lessonList.vue'),
      },
      {
        path: 'lessonEdit',
        meta: {
          title: '功课编辑',
        },
        component: () => import('@/pages/music/lessonEdit.vue'),
      },
    ],
  },

  {
    meta: {
      title: '逝者',
    },
    path: '/deceased',
    component: Layout,
    children: [
      {
        path: 'enshrineList',
        name: 'enshrineList',
        meta: {
          title: '逝者首页',
        },
        component: () => import('@/pages/deceased/enshrineList/index.vue'),
      },
      {
        path: 'deceasedList',
        name: 'deceasedList',
        meta: {
          title: '逝者列表',
        },
        component: () => import('@/pages/deceased/list.vue'),
      },
      {
        path: 'info',
        meta: {
          title: '信息',
        },
        component: () => import('@/pages/deceased/info.vue'),
      },
      {
        path: 'detail',
        meta: {
          title: '详情',
        },
        component: () => import('@/pages/deceased/detail.vue'),
      },
    ],
  },
  {
    meta: {
      title: '商城',
    },
    path: '/shop',
    component: Layout,
    children: [
      {
        path: 'list',
        meta: {
          title: '商城',
          showBottom: true,
          trackName: 'enter_shangcheng_tab',
        },
        component: () => import('@/pages/shop/list.vue'),
      },
      {
        path: 'goodsDetail',
        name: 'goodsDetail',
        meta: {
          title: '商品详情',
        },
        component: () => import('@/pages/shop/goodsDetail.vue'),
      },
      {
        path: 'machineList',
        meta: {
          title: '神台机列表',
        },
        component: () => import('@/pages/shop/machineList.vue'),
      },
      {
        path: 'paySuccess',
        meta: {
          title: '支付结果',
        },
        component: () => import('@/pages/shop/paySuccess.vue'),
      },
      {
        path: 'unpaid',
        name: 'unpaid',
        meta: {
          title: '待付款',
        },
        component: () => import('@/pages/shop/orderList/orderCardList.vue'),
      },
      {
        path: 'unshipped',
        name: 'unshipped',
        meta: {
          title: '待发货',
        },
        component: () => import('@/pages/shop/orderList/orderCardList.vue'),
      },
      {
        path: 'unreceived',
        name: 'unreceived',
        meta: {
          title: '待收货',
        },
        component: () => import('@/pages/shop/orderList/orderCardList.vue'),
      },
      {
        path: 'allorder',
        name: 'allorder',
        meta: {
          title: '全部订单',
        },
        component: () => import('@/pages/shop/orderList/orderCardList.vue'),
      },
      {
        path: 'completed',
        name: 'completed',
        meta: {
          title: '已完成',
        },
        component: () => import('@/pages/shop/orderList/orderCardList.vue'),
      },
      {
        path: 'orderInfo',
        name: 'orderInfo',
        meta: {
          title: '订单详情',
        },
        component: () => import('@/pages/shop/orderList/orderInfo.vue'),
      },
    ],
  },
  {
    meta: {
      title: '会员福利',
    },
    path: '/vip',
    component: Layout,
    children: [
      {
        path: 'index',
        meta: {
          title: '会员福利',
        },
        component: () => import('@/pages/vip/index.vue'),
      },
      {
        path: 'exchange',
        meta: {
          title: '福利兑换',
        },
        component: () => import('@/pages/vip/exchange.vue'),
      },
      {
        path: 'privilege',
        meta: {
          title: 'VIP特权页',
        },
        component: () => import('@/pages/vip/privilege.vue'),
      },
    ],
  },
  {
    meta: {
      title: '教程',
    },
    path: '/help',
    component: Layout,
    children: [
      {
        path: 'use',
        meta: {
          title: '使用教程',
        },
        component: () => import('@/pages/helper/use.vue'),
      },
    ],
  },
  {
    meta: {
      title: '工具',
    },
    path: '/tools',
    component: Layout,
    children: [
      {
        path: 'calendar',
        name: 'calendar',
        meta: {
          title: '工具',
          showBottom: true,
          trackName: 'enter_gongju_tab',
        },
        component: () => import('@/pages/tools/calendar.vue'),
      },
      {
        path: 'festivalDetail',
        meta: {
          title: '节日详情',
        },
        component: () => import('@/pages/tools/festivalDetail.vue'),
      },
    ],
  },
  {
    meta: {
      title: '资讯',
    },
    path: '/news',
    component: Layout,
    children: [
      {
        path: 'index',
        meta: {
          title: '资讯',
          showBottom: true,
          trackName: 'enter_zixun_tab',
        },
        component: () => import('@/pages/news/index.vue'),
      },
      {
        path: 'subIndex',
        meta: {
          title: '资讯',
        },
        component: () => import('@/pages/news/subIndex.vue'),
      },
      {
        path: 'detail',
        meta: {
          title: '资讯',
        },
        component: () => import('@/pages/news/detail.vue'),
      },
    ],
  },
  {
    meta: {
      title: '活动',
    },
    path: '/activity',
    component: Layout,
    children: [
      {
        path: 'fudeGift',
        meta: {
          title: '功德礼包',
        },
        component: () => import('@/pages/activity/fudeGift'),
      },
      {
        path: 'xiangxunGift',
        name: 'xiangxun-gift',
        meta: {
          title: '香薰兑换',
        },
        component: () => import('@/pages/activity/xiangxunGift'),
      },
    ],
  },
];
