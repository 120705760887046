<template>
  <div class="index-container" id="god-index">
    <template v-if="godInfo">
      <div class="index-out-box">
        <img
          :src="godInfo.title_img"
          class="god-title-img"
          v-if="['jade_emperor', 'yu_chan_cai_shen'].includes(godInfo.index)"
        />

        <img :src="godInfo.wap_desk_image || defaultImg.wap_desk_image" class="desk" />
        <div class="index-box">
          <template v-if="godInfo.index === 'jade_emperor'">
            <img src="https://img-fe.tengzhihh.com/image/41de845b601188-750x135.png" class="weilan-img" />
            <img src="https://img-fe.tengzhihh.com/image/2f197e62357e0e-118x114.png" class="logo-img" @click="jump" />
            <img src="https://img-fe.tengzhihh.com/image/abec33f6d12ffe-132x362.png" class="left-bird" />
            <img src="https://img-fe.tengzhihh.com/image/8b39866eaaf9b5-132x362.png" class="right-bird" />
            <img src="https://img-fe.tengzhihh.com/image/8ad78493251219-568x904.png" class="god-cover-img" />

            <div :id="`left-zhuzi-svga-${godId}`" class="left-zhuzi-svga"></div>
            <div :id="`right-zhuzi-svga-${godId}`" class="right-zhuzi-svga"></div>
            <div :id="`god-svga-${godId}`" class="god-svga"></div>
            <div :id="`lizi-svga-${godId}`" class="lizi-svga"></div>
            <div :id="`top-yun-svga-${godId}`" class="top-yun-svga" @click="openPopup('SendGodRef')"></div>
            <div :id="`bottom-yun-svga-${godId}`" class="bottom-yun-svga"></div>
            <div :id="`left-bird-svga-${godId}`" class="left-bird-svga"></div>
            <div :id="`right-bird-svga-${godId}`" class="right-bird-svga"></div>
            <div :id="`circle-svga-${godId}`" class="circle-svga"></div>
          </template>

          <img
            v-if="emptyIndex"
            :src="
              indexMode === 'western'
                ? 'https://img-fe.tengzhihh.com/image/eb850b70884c6bfc27601cd218af2fb0.png'
                : 'https://img-fe.tengzhihh.com/image/42b0cdfbafa94e-568x904.png'
            "
            class="god-img"
            @click="$utils.checkAuth(serialId, inviteGod)"
          />
          <template v-else-if="godInfo.index === 'yu_chan_cai_shen'">
            <div id="yu_chan_cai_shen" @click="openPopup('SendGodRef')"></div>
            <img src="https://fe-img.tengzhihh.com/image/6862044f-dc85-480c-bd7c-e95d0aeebf25.png" class="ycgcs-left" />
            <img
              src="https://fe-img.tengzhihh.com/image/3973e370-bb32-4d32-bcc0-debc86285fcf.png"
              class="ycgcs-right"
            />
          </template>
          <template v-else-if="godInfo.index === 'shui_yue_guan_yi'">
            <div id="shui_yue_guan_yi" @click="openPopup('SendGodRef')"></div>
            <img
              src="https://fe-img.tengzhihh.com/image/5c4671d2e1c8e56bdf0190cea3168374-70x376.png"
              class="sygy-left"
            />
            <img
              src="https://fe-img.tengzhihh.com/image/17dd6199cdfe438e013483798da8b1f1-70x376.png"
              class="sygy-right"
            />
          </template>
          <div v-else class="god-img">
            <img :src="godInfo.wap_detail_image" @click="openPopup('SendGodRef')" />
          </div>

          <img
            v-if="activeIndex !== 0"
            src="https://img-fe.tengzhihh.com/image/b0c85ea7f1ba40-72x72.png"
            class="arrow arrow-left"
            @click.stop.prevent="updateIndex('left')"
          />
          <img
            v-if="!emptyIndex"
            src="https://img-fe.tengzhihh.com/image/b0c85ea7f1ba40-72x72.png"
            class="arrow arrow-right"
            @click.stop.prevent="updateIndex('right')"
          />

          <div v-show="showYan" :id="`yan-svga-${godId}`" class="yan-svga"></div>

          <!-- <div class="flags-box f fc fbt">
            <img v-if="godInfo.left_golden_flag_image" :src="godInfo.left_golden_flag_image" class="flag-item left" />
            <img v-if="godInfo.right_golden_flag_image" :src="godInfo.right_golden_flag_image" class="flag-item left" />
          </div> -->
          <div class="offerings-box">
            <img :src="flower.offer_left_image" class="offering-item hua left" v-debounce="touchOffering" />
            <img :src="flower.offer_right_image" class="offering-item hua right" v-debounce="touchOffering" />

            <img
              v-if="indexMode !== 'western'"
              :src="incense.offer_left_image"
              class="offering-item xiang"
              v-debounce="touchOffering"
            />

            <img :src="fruit.offer_left_image" class="offering-item shuiguo left" v-debounce="touchOffering" />
            <img :src="fruit.offer_left_image" class="offering-item shuiguo right" v-debounce="touchOffering" />

            <img :src="fireImg" class="fire-ani fire-left" v-if="!emptyIndex && !emptyOfferings" />

            <img :src="candle.offer_left_image" class="offering-item lazhu left" v-debounce="touchOffering" />

            <img :src="fireImg" class="fire-ani fire-right" v-if="!emptyIndex && !emptyOfferings" />
            <img :src="candle.offer_right_image" class="offering-item lazhu right" v-debounce="touchOffering" />
          </div>

          <div class="fude f fc" @click="openFude" v-if="$utils.calcUserIsMasterWithSerialId(serialId)">
            <img class="fude-img" src="https://img-fe.tengzhihh.com/image/83bba5260be65e-50x50.png" />
            <div class="fude-text">香火{{ godInfo.user_god_fude || 0 }}</div>
          </div>

          <div class="buttonList f" v-if="!emptyIndex">
            <img
              v-if="indexMode !== 'western'"
              src="https://img-fe.tengzhihh.com/image/62b7cc5a2cd474b62c6b1a27b7b93a68-200x94.png"
              v-debounce="handleIncense"
              class="incense-image"
            />
            <img
              src="https://img-fe.tengzhihh.com/image/91fb9570abad2837bf5d8adeb09a3d59-200x94.png"
              v-debounce="touchOffering"
              class="Offering-image"
            />
            <img
              src="https://img-fe.tengzhihh.com/image/2ac854114be8cd8f950275a350353e68-200x94.png"
              v-debounce="praywish"
              class="wish-image"
            />
          </div>

          <div class="main-btn-list f">
            <div
              v-if="!emptyIndex && !isDefaultGod"
              :class="`set-main-btn ${indexMode === 'western' ? 'set-main-btn-western' : ''}`"
              @click="$utils.checkAuth(serialId, setDefaultGod)"
            >
              设为主位
            </div>
            <div
              v-if="!emptyIndex"
              :class="`set-main-btn ${indexMode === 'western' ? 'set-main-btn-western' : ''}`"
              v-debounce="autoOffering"
            >
              一键供奉
            </div>
          </div>
          <img
            v-if="godData && godData.worden_fish_icon && !['jade_emperor', 'yu_chan_cai_shen'].includes(godInfo.index)"
            :src="godData.worden_fish_icon"
            alt=""
            class="woodfish"
            @click="woodfishFn"
          />
        </div>
      </div>
    </template>
    <Dialog ref="dialog" />

    <!-- <OfferingsTimeout
      ref="OfferingsTimeoutRef"
      :img="
        indexMode === 'western'
          ? 'https://img-fe.tengzhihh.com/image/aa23958f3dd113-542x206.png'
          : 'https://img-fe.tengzhihh.com/image/96d2270313c49a-542x209.png'
      "
      :time="calcExpireTime"
    /> -->
    <OfferingsTimeout ref="OfferingsTimeoutRef" :offerings="offerings" :time="calcExpireTime" />
    <Fude
      v-if="godInfo"
      ref="FudeRef"
      :godId="godId"
      :godFude="godInfo.user_god_fude"
      :godName="godInfo.name"
      :todayTask="todayTask"
      @goOffer="goOffer"
      @viewFude="viewFude"
    />
    <van-overlay :show="godInfo.status === 2" :lock-scroll="false" class-name="cus-overlay" @scroll.native.prevent>
      <div class="send-expireInterval f fc fvc">
        <span>距离送神</span><span class="time">{{ calcSendGodTime }}</span>
      </div>
      <div class="op-btn" @click="$utils.checkAuth(serialId, cancelSendGod)">取消送神上天</div>
    </van-overlay>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import svgaplayerweb from 'svgaplayerweb';
import { saveQuickOffering } from '@/api/setting';

import GoBack from '@/components/GoBack/GoBack.vue';
import Dialog from '@/components/Dialog/Dialog.vue';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'; // 引入 dayjs-duration 插件
dayjs.extend(duration);
import { mapState } from 'vuex';
import OfferingsTimeout from './OfferingsTimeout.vue';
import Fude from './Fude.vue';

const offerings = [
  {
    offer_left_image: 'https://img-fe.tengzhihh.com/image/8d0c0d62c7419d-218x256.png',
    offer_right_image: 'https://img-fe.tengzhihh.com/image/85034b82c5da8c-218x256.png',
    offer_type: 1,
  },
  {
    offer_left_image: 'https://img-fe.tengzhihh.com/image/62733535a3cf1db682ee0b2edfbc9ff3-160x380.png',

    offer_type: 2,
  },
  {
    offer_left_image: 'https://img-fe.tengzhihh.com/image/a1c69e96509eda-90x136.png',
    offer_right_image: 'https://img-fe.tengzhihh.com/image/1de2bfe1ad88ae-92x136.png',
    offer_type: 3,
  },
  {
    offer_left_image: 'https://img-fe.tengzhihh.com/image/82379177f5a8dd-104x164.png',
    offer_right_image: 'https://img-fe.tengzhihh.com/image/b16d0fae9e1ab5-104x164.png',
    offer_type: 4,
  },
];
const westOfferings = [
  {
    offer_left_image: 'https://img-fe.tengzhihh.com/image/8d0c0d62c7419d-218x256.png',
    offer_right_image: 'https://img-fe.tengzhihh.com/image/85034b82c5da8c-218x256.png',
    offer_type: 1,
  },
  {
    offer_left_image: 'https://img-fe.tengzhihh.com/image/62733535a3cf1db682ee0b2edfbc9ff3-160x380.png',

    offer_type: 2,
  },
  {
    offer_left_image: 'https://img-fe.tengzhihh.com/image/d7d616f4326d83-98x146.png',
    offer_right_image: 'https://img-fe.tengzhihh.com/image/9e0cf833017680-98x146.png',
    offer_type: 3,
  },
  {
    offer_left_image: 'https://img-fe.tengzhihh.com/image/b277a1c773cff8-224x350.png',
    offer_right_image: 'https://img-fe.tengzhihh.com/image/62dbbe8b58d945-224x350.png',
    offer_type: 4,
    is_western: true,
  },
];

export default {
  components: { GoBack, Dialog, OfferingsTimeout, Fude },
  props: {
    serialId: {
      type: String,
      default: '',
    },
    godType: {
      type: [Number, String],
      default: 1,
    },
    godId: {
      type: [Number, String],
      default: 0,
    },
    godData: {
      type: Object,
      default: null,
    },
    emptyIndex: {
      type: Boolean,
      default: false,
    },
    indexMode: {
      type: String,
      default: '',
    },
    religion: {
      type: String,
      default: '',
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
    defaultImg: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      fireImg: 'https://img-fe.tengzhihh.com/image/26b2cc7b06f2e3-207x207.png',
      showYan: false,
      changeFlag: false,
      clickImgType: '',
      clickTimeout: null,
      calcExpireTime: '',
      calcSendGodTime: '',
      sendIntervalCancel: null,
      expireIntervalCancel: null,
      aniInterval: null,
      svgaTimeout: null,
    };
  },
  beforeDestroy() {
    clearTimeout(this.svgaTimeout);
    clearTimeout(this.clickTimeout);
    typeof this.sendIntervalCancel === 'function' && this.sendIntervalCancel();
    typeof this.expireIntervalCancel === 'function' && this.expireIntervalCancel();

    clearInterval(this.aniInterval);
    document.getElementById('video-preview') && document.getElementById('video-preview').remove();
  },
  computed: {
    ...mapState(['siMiaoLianMingGodGoodsId', 'generalConfig', 'commonConfig']),
    godInfo: function () {
      return this.godData?.god || {};
    },
    emptyOfferings: function () {
      const tmp = this.godData?.offerings || [];
      return tmp.length === 0;
    },
    offerings: function () {
      const tmp = this.godData?.offerings || [];
      if (tmp?.length === 0) {
        return this.indexMode === 'western' ? westOfferings : offerings;
      } else {
        return tmp;
      }
    },
    videoUrl: function () {
      return this.godInfo?.video_url;
    },

    todayTask: function () {
      return this.godData?.today_task || [];
    },

    flower: function () {
      return this.offerings.find(i => i.offer_type == 1) || '';
    },
    incense: function () {
      return this.offerings.find(i => i.offer_type == 2) || '';
    },
    fruit: function () {
      return this.offerings.find(i => i.offer_type == 3) || '';
    },
    candle: function () {
      return this.offerings.find(i => i.offer_type == 4) || '';
    },
    offeringsExpireTime: function () {
      return this.offerings.find(i => i.offer_type == 1)?.expire_time || '';
    },
    sendGodTime: function () {
      return this.godInfo?.deliver_end_time || 0;
    },
    isDefaultGod: function () {
      return !!this.godInfo?.is_default;
    },
  },
  watch: {
    clickImgType: function (n) {
      if (n) {
        clearTimeout(this.clickTimeout);
        this.clickTimeout = setTimeout(() => {
          this.clickImgType = '';
        }, 200);
      }
    },

    offeringsExpireTime: {
      handler: function (n) {
        if (n) {
          this.calcExpireTimeFn();
          this.handleExpireTime();
        } else {
          typeof this.expireIntervalCancel === 'function' && this.expireIntervalCancel();
        }
      },
      immediate: true,
    },
    sendGodTime: {
      handler: function (n) {
        if (n) {
          this.calcSendGodTimeFn();
          this.handleSendGodTime();
        } else {
          typeof this.sendIntervalCancel === 'function' && this.sendIntervalCancel();
        }
      },
      immediate: true,
    },
    'godInfo.index': {
      handler: function (index) {
        if (index === 'shui_yue_guan_yi') {
          this.$nextTick(() => {
            this.playSvga(
              `#shui_yue_guan_yi`,
              'https://fe-img.tengzhihh.com/file/033211a3-1288-4a6f-82c3-0c165b028854.svga'
            );
          });
        } else if (index === 'jade_emperor') {
          this.svgaTimeout = setTimeout(() => {
            this.startPlay();
          }, 2000);
        } else if (index === 'yu_chan_cai_shen') {
          this.$nextTick(() => {
            this.playSvga(
              `#yu_chan_cai_shen`,
              'https://img-fe.tengzhihh.com/file/4cff573e-629f-4e21-8085-6454294b6ecb.svga'
            );
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    updateIndex(e) {
      this.$emit('updateIndex', e);
    },
    setDefaultGod() {
      this.$track('gongfeng_sheweizhuwei_click');
      this.$refs.dialog.initDialog({
        title: '设为主位',
        content: `是否设置该神明为主位，确定后将作为供奉顺序的第一位，优先展示在首页神龛(神柜)里。`,
        cancelText: '取消',
        confirmText: '确定',
        confirmCallback: () => {
          this.$http
            .post(apiPath.setDefaultGod, {
              serial_id: this.serialId,
              god_type: this.godType,
              god_id: this.godId,
            })
            .then(() => {
              this.$track('gongfeng_replace_zhuwei_shenxiang');
              this.$refs.dialog.closeDialog();
              this.$toast('设置成功');
              this.$emit('updateGodList', this.godId);
            });
        },
        cancelCallback: () => {
          this.$refs.dialog.closeDialog();
        },
      });
    },
    jump() {
      const url = this.godInfo?.introduce_url;
      url && (location.href = url);
    },
    async playSvga(dom, svga, loops, endCb) {
      const player = new svgaplayerweb.Player(dom);
      const parser = new svgaplayerweb.Parser(dom);
      parser.load(svga, videoItem => {
        player.clearsAfterStop = true; // 动画结束时，是否清空画布
        loops && (player.loops = loops);
        player.setVideoItem(videoItem);
        player.startAnimation();
        player.onFinished(() => {
          typeof endCb === 'function' && endCb();
        });
      });
    },

    startPlay() {
      this.$nextTick(() => {
        this.playSvga(`#left-zhuzi-svga-${this.godId}`, 'https://img-fe.tengzhihh.com/other/5c73476773cfa6.svga');
        this.playSvga(`#right-zhuzi-svga-${this.godId}`, 'https://img-fe.tengzhihh.com/other/5c73476773cfa6.svga');
        this.playSvga(`#god-svga-${this.godId}`, 'https://img-fe.tengzhihh.com/audio/72a502afde5c27.svga');
        this.playSvga(`#lizi-svga-${this.godId}`, 'https://img-fe.tengzhihh.com/audio/3b38cd5430f234.svga');

        this.playSvga(`#top-yun-svga-${this.godId}`, 'https://img-fe.tengzhihh.com/audio/d9a744c9e20da0.svga');
        this.playSvga(`#bottom-yun-svga-${this.godId}`, 'https://img-fe.tengzhihh.com/audio/4fbb7c2967717d.svga');

        this.playSvga(`#left-bird-svga-${this.godId}`, 'https://img-fe.tengzhihh.com/audio/3130322802e707.svga');
        this.playSvga(`#right-bird-svga-${this.godId}`, 'https://img-fe.tengzhihh.com/other/7ba7c02f207458.svga');
        this.playSvga(`#circle-svga-${this.godId}`, 'https://img-fe.tengzhihh.com/audio/874f4b6f335117.svga');
      });
    },
    viewFude() {
      this.$emit('setValue', 'activeClose', true);
      this.$router.push(`/fude/list?fude_type=user_god_fude&god_id=${this.godId}&serial_id=${this.serialId}`);
    },
    cancelSendGod() {
      this.$refs.dialog.initDialog({
        title: '取消恭送神明',
        content: `是否继续供奉该神明？`,
        confirmText: '确定',
        cancelText: '取消',
        cancelCallback: () => {
          this.$refs.dialog.closeDialog();
        },
        confirmCallback: () => {
          this.$refs.dialog.closeDialog();
          this.$http
            .post(apiPath.cancelSendGod, {
              god_id: this.godId,
              serial_id: this.serialId,
              ver: this.commonConfig.ver,
            })
            .then(() => {
              this.$track('gongfeng_songshen_cancel');
              this.$toast('操作成功');
              this.$emit('updateGodList', this.godInfo?.id);
            });
        },
      });
    },
    goOffer() {
      typeof this.$refs.FudeRef.onCancel === 'function' && this.$refs.FudeRef.onCancel();
      this.$emit('setValue', 'pageStep', 'offerings');
    },
    openFude() {
      this.$track('gongfeng_xianghuo_click');
      typeof this.$refs.FudeRef.show === 'function' && this.$refs.FudeRef.show();
    },
    touchOffering() {
      if (!this.emptyOfferings) {
        typeof this.$refs.OfferingsTimeoutRef.show === 'function' && this.$refs.OfferingsTimeoutRef.show();
      } else {
        !this.emptyIndex && this.setOfferings();
      }
    },
    autoOffering() {
      this.$track('H5_gongfeng_one_click_all');
      this.$track('H5_shenming_gongfeng_one_click');
      if (!this.emptyOfferings) {
        typeof this.$refs.OfferingsTimeoutRef.show === 'function' && this.$refs.OfferingsTimeoutRef.show();
        return;
      }
      if (!this.godData.is_set_quick_offer) {
        this.toEnshrineSetting();
        return;
      }
      saveQuickOffering({
        serial_id: this.serialId,
        god_type: this.godType,
      })
        .then(res => {
          this.$toast('一键上供成功');
          this.$emit('updateGodList', this.godInfo?.id);
        })
        .catch(res => {
          if (res.code === 400504) {
            this.toEnshrineSetting();
          }
        });
    },
    toEnshrineSetting() {
      this.$refs.dialog.initDialog({
        title: '一键供奉',
        content: `你当前还没有完成设置“一键供奉”的操作。设置成功后即可一键点击，为正在供奉的所有神明上供贡品。`,
        confirmText: '去设置',
        showCancelBtn: false,
        confirmCallback: () => {
          this.$router.push({
            name: 'enshrineSetting',
            query: Object.assign({ god_type: this.godType }, this.$route.query),
          });
        },
      });
    },
    handleSendGodTime() {
      this.sendIntervalCancel = this.$tools.myInterval(this.calcSendGodTimeFn, 1000);
    },
    calcSendGodTimeFn() {
      const now = dayjs().unix();
      const sub = this.sendGodTime - now;

      if ([0, -3, -5, -8, -12].includes(sub) && this.sendGodTime) {
        this.$emit('updateGodList', this.godInfo?.id);
      }
      this.calcSendGodTime = dayjs.duration(sub, 'seconds').format('H:m:ss');
    },
    calcExpireTimeFn() {
      const now = dayjs().unix();
      const sub = this.offeringsExpireTime - now;

      if ([0, -3, -5, -8, -12].includes(sub) && this.offeringsExpireTime) {
        clearInterval(this.expireInterval);
        this.$emit('updateGodList', this.godInfo?.id);
      }
      this.calcExpireTime = dayjs.duration(sub, 'seconds').format('H时m分ss秒');
    },
    handleExpireTime() {
      this.expireIntervalCancel = this.$tools.myInterval(this.calcExpireTimeFn, 1000);
    },
    inviteGod() {
      this.$track('gongfeng_kong_gongqing_click');
      this.$emit('setValue', 'pageStep', 'list');
    },

    showVideo() {
      const dom = document.createElement('video');
      dom.setAttribute('src', this.videoUrl);
      dom.setAttribute('id', 'video-preview');
      dom.setAttribute('controls', true);
      dom.setAttribute('muted', true);
      dom.setAttribute('autoplay', true);
      dom.onended = function () {
        document.getElementById('video-preview') && document.getElementById('video-preview').remove();
      };
      document.getElementById('god-index').appendChild(dom);
      dom.play();
    },

    setGod() {
      const formData = new FormData();
      formData.append('serial_id', this.serialId);
      formData.append('god_type', this.godType);

      formData.append('god_id', this.godInfo?.id);
      this.$http.formPost(apiPath.setGod, formData);
    },

    handleIncense() {
      this.clickImgType = 'incense';
      this.$utils.useIncense(
        {
          serial_id: this.serialId,
          from: this.godType == 1 ? 'god' : 'earth_god',
          id: this.godInfo?.id,
          incense_capacity: this.generalConfig.incenseCapacity,
        },
        () => {
          !this.emptyIndex && !this.emptyOfferings && this.handleYan();
        }
      );
    },
    handleYan() {
      this.showYan = true;
      this.playSvga(
        `#yan-svga-${this.godId}`,
        'https://mmc-forecast.oss-cn-shanghai.aliyuncs.com/file/1ef5301c-fa83-4e5a-97da-47b9d8c149b3.svga',
        1,
        () => {
          this.showYan = false;
        }
      );
    },

    changeGod() {
      this.$emit('setValue', 'pageStep', 'list');
    },

    openPopup(type) {
      if (this.emptyIndex) {
        return false;
      }
      this.$emit('openPopup', type, this.godId);
    },

    setOfferings() {
      this.$emit('setValue', 'pageStep', 'offerings');
      this.$tools.setDocumentTitle('供奉');
    },

    woodfishFn() {
      this.$track('H5_gongfeng_muyu_click');
      this.$router.push({
        name: 'woodfish',
        query: {
          serial_id: this.$route.query.serial_id,
        },
      });
    },
    praywish() {
      this.$router.push({
        name: 'wishInfoIndex',
        query: {
          serial_id: this.$route.query.serial_id,
          god_id: this.godId,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.index-container {
  width: 100%;
  height: 821px;
  position: relative;
  .fude {
    width: fit-content;
    height: 25px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 13px 13px 13px 13px;
    padding: 0 10px;
    position: absolute;
    right: 12px;
    z-index: 5;
    top: 50px;
    .fude-text {
      font-size: 12px;
      font-weight: 400;
      color: #ff913e;
    }
    .fude-img {
      width: 25px;
      height: 25px;
    }
  }
  .index-out-box {
    width: 100%;
    height: 100%;

    .god-title-img {
      position: absolute;
      width: 149px;
      height: 52px;
      top: 54px;
      left: 50%;
      transform: translateX(-50%);
    }
    .desk {
      position: absolute;
      width: 375px;
      height: 218px;
      top: 424px;
    }
  }

  .index-box {
    position: relative;
    min-height: 642px;
    overflow: hidden;
    #yu_chan_cai_shen {
      width: 100%;
      height: 821px;
      position: absolute;
      top: -95px;
      transform: scale(1.27);
    }
    .ycgcs-left {
      position: absolute;
      width: 63px;
      height: 158px;
      top: 470px;
      left: 0;
    }
    .ycgcs-right {
      position: absolute;
      width: 63px;
      height: 158px;
      top: 470px;
      right: 1px;
    }
    // 玉皇大帝start
    .logo-img {
      width: 59px;
      height: 57px;
      position: absolute;
      top: 94px;
      right: 32px;
      z-index: 5;
    }
    .weilan-img {
      width: 375px;
      height: 67px;
      position: absolute;
      top: 392px;
      z-index: 3;
    }
    .god-cover-img {
      position: absolute;
      width: 280px;
      left: 50%;
      margin-left: -140px;
      z-index: 3;
    }
    .left-bird {
      width: 70px;
      height: 190px;
      position: absolute;
      left: 1px;
      top: 420px;
      z-index: 5;
    }
    .right-bird {
      width: 70px;
      height: 190px;
      position: absolute;
      right: 1px;
      top: 420px;
      z-index: 5;
    }
    .left-zhuzi-svga {
      position: absolute;
      width: 40px;
      height: 280px;
      left: 7px;
      top: 98px;
    }
    .right-zhuzi-svga {
      position: absolute;
      width: 40px;
      height: 280px;
      right: 4px;
      top: 98px;
    }
    .god-svga {
      width: 280px;
      height: 520px;
      position: absolute;
      left: 50%;
      margin-left: -140px;
      top: 38px;
      z-index: 2;
    }
    .circle-svga {
      position: absolute;
      width: 250px;
      height: 250px;
      margin-left: -125px;
      top: 20px;
      left: 50%;
    }
    .lizi-svga {
      width: 284px;
      height: 540px;
      position: absolute;
      left: 50%;
      margin-left: -140px;
      top: 70px;
      z-index: 3;
    }
    .top-yun-svga {
      width: 375px;
      height: 375px;
      position: absolute;
      right: 0;
      top: 116px;
      z-index: 3;
    }
    .bottom-yun-svga {
      width: 375px;
      height: 469px;
      position: absolute;
      right: 0;
      top: 50px;
      z-index: 2;
    }
    .left-bird-svga {
      position: absolute;
      width: 70px;
      height: 190px;
      left: -3px;
      top: 420px;
      z-index: 4;
    }
    .right-bird-svga {
      position: absolute;
      width: 70px;
      height: 190px;
      right: -6px;
      top: 427px;
      z-index: 4;
    }

    // 玉皇大帝end

    // 水月观音start
    .sygy-left {
      width: 40px;
      position: absolute;
      left: 3px;
      bottom: 30px;
      z-index: 5;
    }
    .sygy-right {
      width: 40px;
      position: absolute;
      right: 3px;
      bottom: 30px;
      z-index: 5;
    }
    #shui_yue_guan_yi {
      width: 100%;
      height: 469px;
      position: absolute;
      top: -2px;
      z-index: unset !important;
    }
    // 水月观音end

    .god-img {
      position: absolute;
      width: 280px;
      height: 446px;
      left: 50%;
      margin-left: -140px;
      z-index: 2;
      display: flex;
      align-items: flex-end;
      img {
        width: 100%;
        height: auto;
      }
    }
    .arrow {
      position: absolute;
      width: 36px;
      height: 36px;
      top: 300px;
      z-index: 2200;
    }
    .arrow-left {
      left: 10px;
      transform: rotate(180deg);
    }
    .arrow-right {
      right: 10px;
    }

    .yan-svga {
      position: absolute;
      left: 50%;
      width: 70px;
      height: 120px;
      margin-left: -35px;
      top: 255px;
      z-index: 5;
    }
    .flags-box {
      width: 100%;
      .flag-item {
        width: 58px;
        height: 336px;
      }
    }
    .offerings-box {
      position: relative;
      z-index: 4;
      .offering-item {
        position: absolute;
        z-index: 2;
      }

      .fire-ani {
        width: 90px;
        height: 90px;
        z-index: 100;
        position: absolute;
      }
      .fire-left {
        top: 390px;
        left: 82px;
      }
      .fire-right {
        top: 390px;
        right: 76px;
      }
      .hua.left {
        width: 101px;
        top: 343px;
        left: 56px;
      }
      .hua.right {
        width: 101px;
        top: 343px;
        right: 54px;
      }

      .shuiguo.left {
        width: 46px;

        top: 447px;
        left: 141px;
      }
      .shuiguo.right {
        width: 46px;
        top: 447px;
        right: 138px;
      }

      .xiang {
        left: 50%;
        width: 40px;
        margin-left: -20px;
        top: 368px;
      }

      .lazhu.left {
        width: 52px;
        height: 82px;
        top: 433px;
        left: 102px;
        z-index: 200;
      }
      .lazhu.right {
        width: 52px;
        height: 82px;
        top: 433px;
        right: 96px;
        z-index: 200;
      }
    }
    .buttonList {
      position: absolute;
      top: 518px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 100;
      .incense-image {
        width: 100px;
        height: 47px;
      }
      .Offering-image {
        width: 100px;
        height: 47px;
      }
      .wish-image {
        width: 100px;
        height: 47px;
      }
    }
    .main-btn-list {
      position: absolute;
      top: 600px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2000;
      .set-main-btn {
        width: 90px;
        height: 25px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 13px;
        border: 1px solid #f87a1b;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        color: #f87a1b;
        margin: 0 5px;
      }
      .set-main-btn-western {
        border: 1px solid #fff;
        color: #fff;
      }
    }
    .tip-box {
      text-align: center;
      width: 100%;
      height: 34px;
      line-height: 34px;
      background: rgba(0, 0, 0, 0.5);
      color: #eb1b1b;
      font-size: 14px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .woodfish {
      position: absolute;
      width: 66px;
      top: 555px;
      left: 300px;
      z-index: 100;
    }
  }
  .cus-overlay {
    position: absolute !important;
    z-index: 2024;
    height: 100% !important;
    .send-expireInterval {
      position: absolute;
      top: 50px;
      left: 20px;
      width: fit-content;
      height: 25px;
      line-height: 25px;
      text-align: center;
      padding: 0 10px;
      box-sizing: border-box;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 13px;
      color: white;
      font-size: 14px;
      min-width: 140px;
      .time {
        color: #ff913e;
      }
    }
    .op-btn {
      width: 146px;
      height: 38px;
      background: linear-gradient(180deg, #fcb882 0%, #f87a1b 100%);
      border-radius: 19px 19px 19px 19px;
      line-height: 38px;
      text-align: center;
      font-size: 14px;
      color: #fff;
      position: absolute;
      left: 50%;
      margin-left: -73px;
      top: 480px;
    }
  }
}
.popup {
  width: 100%;
  background: #fff;
  border-radius: 10px 10px 0 0;
  .popup-box {
    width: 100%;
    height: 230px;
  }
  .tip {
    font-size: 20px;
    font-weight: bold;
    color: #000;
    margin: 18px 0 38px;
  }
  .btn {
    width: 228px;
    height: 40px;
    line-height: 40px;
    background: #f87a1b;
    border-radius: 10px;
    font-size: 16px;
    color: #fff;
    margin-bottom: 16px;
    border: none;
  }
}

.dialog-box {
  width: 295px;
  max-height: 70vh;
  padding: 0 0 20px;
  background: #fff;
  border-radius: 15px;

  .tip {
    font-size: 20px;
    color: #2e2d2d;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .content-box {
    padding: 10px 30px;
    overflow-y: scroll;
    max-height: 80%;
    .content {
      text-align: justify;
      font-size: 14px;
      color: #707070;
    }
  }

  .btn-box {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 22px 0 0;
    .cancel-btn {
      width: 100px;
      height: 40px;
      background: #f6f6f6;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.65);
      border-radius: 10px;
    }

    .confirm-btn {
      width: 100px;
      height: 40px;
      background: #f87a1b;
      border-radius: 10px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      color: #ffeac9;
    }
    .big-btn {
      width: 235px !important;
    }
  }
}
</style>
<style lang="less">
#video-preview {
  width: 100vw !important;
  height: calc(var(--vh) * 100) !important;
  z-index: 3000;
  position: fixed;
  left: 0;
  top: 0;
  background: black;
}
</style>
