<template>
  <div class="my-index-container">
    <div class="my-index-header f fc">
      <div class="my-avatar f fc fvc">
        <img :src="$tools.compressImg(userInfo.avatar, 70)" v-if="userInfo.avatar" />
      </div>
      <div class="f fc fbt" @click="jumptoInfo" style="flex: 1">
        <div>
          <div class="name">
            {{ userInfo.name }}<img v-if="userInfo.is_vip" class="vip-icon" :src="userInfo.vip_info.vip_icon" />
          </div>
          <div class="client">{{ devicesNumber }}台设备</div>
        </div>
        <div>
          <van-icon name="arrow" class="right-arrow-icon" />
        </div>
      </div>
    </div>
    <div class="vip-btn" v-if="userInfo.is_vip" @click="goVipPrivilege">
      <img
        class="vip-bg-img"
        src="https://fe-img.tengzhihh.com/image/5491d4c23c742fcaacba42fc8e444908-690x80.png"
        alt=""
      />
      <img class="arrow" src="https://fe-img.tengzhihh.com/image/c4a4c70b74e1071f1318837ab62e7656-33x32.png" alt="" />
    </div>
    <!-- <div class="vip-container" v-if="userInfo.is_vip">
      <div class="vip-info">
        <img class="bg-img" :src="userInfo.vip_info.bg_img" alt="" srcset="" clas />
        <img class="front-img" :src="userInfo.vip_info.front_img" alt="" srcset="" />
        <p class="vip-validity">{{ userInfo.vip_info.txt }}</p>
      </div>
    </div> -->
    <div class="tool-box f fv fc">
      <img src="https://img-fe.tengzhihh.com/image/29ac80cda9f24b-648x158.png" class="top-1" @click="goCalendar" />
      <!-- <div class="bottom-box f fc fbt">
        <img src="https://img-fe.tengzhihh.com/image/53e5f769264030-309x108.png" class="bottom-l" />
        <img src="https://img-fe.tengzhihh.com/image/8ff6cbe61ece5c-309x108.png" class="bottom-l" />
      </div> -->
    </div>
    <div class="cell-box">
      <grid-list :list="orderList" @click="gridClick" />
    </div>
    <div class="cell-box">
      <cell-group :list="settingList"></cell-group>
    </div>
    <Dialog ref="dialog" />
  </div>
</template>

<script>
import Dialog from '@/components/Dialog/Dialog.vue';
import CellGroup from '@/components/CellGroup/CellGroup.vue';
import GridList from '@/components/GridList';
import { mapState } from 'vuex';
import apiPath from '@/utils/apiPath';
import { getBadge } from '@/api/order';
export default {
  components: { CellGroup, Dialog, GridList },
  data() {
    return {
      orderList: [
        {
          name: 'unpaid',
          label: '待付款',
          icon: 'https://img-fe.tengzhihh.com/image/db1eede72a1be7-24x24.png',
          badge: '',
          badgeName: 'unpaid',
        },
        {
          name: 'unshipped',
          label: '待发货',
          icon: 'https://img-fe.tengzhihh.com/image/59ac5afcfb1bd6-24x24.png',
          badge: '',
          badgeName: 'paid',
        },
        {
          name: 'unreceived',
          label: '待收货',
          icon: 'https://img-fe.tengzhihh.com/image/5efd1bb2182579-24x24.png',
          badge: '',
          badgeName: 'send',
        },
        {
          name: 'allorder',
          label: '全部订单',
          icon: 'https://img-fe.tengzhihh.com/image/437a0e5ca9ab60-24x24.png',
          badge: '',
          badgeName: '',
        },
      ],
      list: [
        {
          title: '我的订单',
          isLink: true,
          icon: 'https://img-fe.tengzhihh.com/image/5976774e18c90c-40x40.png',
          path: '/shop/orderList',
        },
        // {
        //   title: '我的钱包',
        //   isLink: true,
        // icon:'https://img-fe.tengzhihh.com/image/2d853f1a271133-40x40.png',
        //   path: '/my/wallet',
        // },
      ],
      settingList: [
        {
          title: '消息中心',
          isLink: true,
          path: '/msg/list',
          icon: 'https://img-fe.tengzhihh.com/image/1194a8e629ffe5-40x40.png',
        },
        {
          title: '操作说明',
          isLink: true,
          path: '/my/instructions',
          icon: 'https://img-fe.tengzhihh.com/image/cd0ecf1bfd5b57-40x40.png',
        },
        {
          title: '帮助中心',
          isLink: true,
          icon: 'https://img-fe.tengzhihh.com/image/881d17e1e407bc-40x40.png',
          cb: () => {
            this.$utils.kefuDialog(this.$refs.dialog);
          },
        },
        {
          title: '设置',
          isLink: true,
          path: '/my/settings',
          icon: 'https://img-fe.tengzhihh.com/image/801272c55623a2-40x40.png',
        },
      ],
    };
  },
  mounted() {
    this.$utils.checkToken(() => {
      this.getBadge();
    }, this.$route.path);
  },
  computed: {
    ...mapState(['userInfo', 'devicesNumber', 'env']),
  },

  methods: {
    async getBadge() {
      const { data } = await getBadge();
      this.orderList = this.orderList.map(x => {
        if (data[x.badgeName]) {
          x.badge = data[x.badgeName];
        }
        return x;
      });
    },
    goCalendar() {
      this.$router.push('/tools/calendar');
    },
    goVipPrivilege() {
      this.$router.push('/vip/privilege');
    },
    jumptoInfo() {
      this.$router.push('/my/info');
    },
    gridClick({ name, badge }) {
      this.$router.push(`/shop/${name}`);
    },
  },
};
</script>

<style lang="less" scoped>
.my-index-container {
  min-height: calc(var(--vh) * 100 - 50px);
  background-color: #f5f5f5;

  .my-index-header {
    padding: 20px 20px 0;
    margin-bottom: 16px;
    .my-avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 20px;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
    .name {
      font-size: 18px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      position: relative;
      .vip-icon {
        position: absolute;
        display: block;
        width: 52px;
        height: 20px;
        right: -70px;
        top: 1px;
      }
    }
    .client {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
  .vip-btn {
    margin: 0 auto 12px;
    position: relative;
    width: 345px;

    .vip-bg-img {
      display: block;
      width: 100%;
      height: 100%;
    }
    .arrow {
      position: absolute;
      right: 16px;
      top: 50%;
      width: 16px;
      transform: translateY(-50%);
    }
  }

  .vip-container {
    position: relative;
    margin: 16px auto 12px;
    width: 345px;

    // .vip-info {
    //   position: relative;
    //   .bg-img {
    //     display: block;
    //     width: 100%;
    //     height: 100%;
    //   }
    //   .front-img {
    //     position: absolute;
    //     width: 68px;
    //     height: 29px;
    //     top: 14px;
    //     left: 16px;
    //   }
    //   .vip-validity {
    //     position: absolute;
    //     top: 47px;
    //     left: 16px;
    //     font-size: 12px;
    //     line-height: 17px;
    //     color: #dc9131;
    //   }
    // }
  }
  .right-arrow-icon {
    font-size: 17px;
    color: #969799;
  }
  .tool-box {
    width: 345px;
    overflow: hidden;
    margin: 0 auto 12px;
    box-sizing: border-box;
    .top-1 {
      width: 100%;
      height: 80px;
    }
    .bottom-box {
      width: 324px;
      .bottom-l {
        width: 155px;
        height: 54px;
      }
    }
  }
  .cell-box {
    width: 345px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto 20px;
  }
}
</style>
