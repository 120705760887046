var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show || _vm.showFields
    ? _c(
        "div",
        [
          _c(
            "van-popup",
            {
              staticClass: "form-popup",
              model: {
                value: _vm.show,
                callback: function ($$v) {
                  _vm.show = $$v
                },
                expression: "show",
              },
            },
            [
              _vm.info && _vm.info.gift
                ? _c("img", {
                    staticClass: "img",
                    attrs: { src: _vm.info.gift.img, alt: "" },
                  })
                : _vm._e(),
              _c("div", { staticClass: "title" }, [_vm._v("选择邮寄地址")]),
              _c(
                "van-form",
                {
                  attrs: { "validate-first": "", readonly: "" },
                  on: { submit: _vm.onSubmit },
                },
                [
                  _vm.hasAddressInfo
                    ? [
                        _c("van-field", {
                          attrs: {
                            name: "name",
                            placeholder: "收件人名称",
                            rules: [
                              { required: true, message: "请填写收件人名称" },
                              {
                                pattern: /^.{0,12}$/,
                                message: "长度不超过12个字",
                              },
                            ],
                            border: false,
                          },
                          model: {
                            value: _vm.name,
                            callback: function ($$v) {
                              _vm.name = $$v
                            },
                            expression: "name",
                          },
                        }),
                        _c("van-field", {
                          attrs: {
                            name: "phone",
                            placeholder: "收件人手机号",
                            maxlength: 11,
                            rules: [
                              { required: true, message: "请填写手机号码" },
                              {
                                pattern: /^1[3-9]\d{9}$/,
                                message: "请输入正确手机号",
                              },
                            ],
                            border: false,
                          },
                          model: {
                            value: _vm.phone,
                            callback: function ($$v) {
                              _vm.phone = $$v
                            },
                            expression: "phone",
                          },
                        }),
                        _c("van-field", {
                          attrs: {
                            name: "address",
                            autosize: "",
                            placeholder: "收件人地址",
                            type: "textarea",
                            rules: [
                              { required: true, message: "请填写收件人地址" },
                              {
                                pattern: /^.{0,50}$/,
                                message: "长度不超过50个字",
                              },
                            ],
                            border: false,
                          },
                          model: {
                            value: _vm.address,
                            callback: function ($$v) {
                              _vm.address = $$v
                            },
                            expression: "address",
                          },
                        }),
                      ]
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "address-btn",
                      attrs: {
                        round: "",
                        block: "",
                        plain: "",
                        color: "#ff8e38",
                      },
                      on: { click: _vm.onGoChoseAddress },
                    },
                    [_vm._v("选择地址")]
                  ),
                  _c(
                    "van-button",
                    {
                      attrs: {
                        round: "",
                        block: "",
                        color: "#ff8e38",
                        disabled: !_vm.hasAddressInfo,
                        "native-type": "submit",
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "van-popup",
            {
              staticClass: "confirm-popup",
              model: {
                value: _vm.showFields,
                callback: function ($$v) {
                  _vm.showFields = $$v
                },
                expression: "showFields",
              },
            },
            [
              _c("div", { staticClass: "confirm-title" }, [
                _vm._v("确认兑换信息"),
              ]),
              _c(
                "div",
                { staticClass: "confirm-content" },
                [
                  _c("div", { staticClass: "confirm-item" }, [
                    _c("span", { staticClass: "confirm-label" }, [
                      _vm._v("收件人名称"),
                    ]),
                    _c("span", { staticClass: "confirm-value" }, [
                      _vm._v(_vm._s(_vm.name)),
                    ]),
                  ]),
                  _c("div", { staticClass: "confirm-item" }, [
                    _c("span", { staticClass: "confirm-label" }, [
                      _vm._v("收件人手机号"),
                    ]),
                    _c("span", { staticClass: "confirm-value" }, [
                      _vm._v(_vm._s(_vm.phone)),
                    ]),
                  ]),
                  _c("div", { staticClass: "confirm-item" }, [
                    _c("span", { staticClass: "confirm-label" }, [
                      _vm._v("收件人地址"),
                    ]),
                    _c("span", { staticClass: "confirm-value" }, [
                      _vm._v(_vm._s(_vm.address)),
                    ]),
                  ]),
                  _vm.info && _vm.info.gift
                    ? _c("div", { staticClass: "confirm-item" }, [
                        _c("span", { staticClass: "confirm-label" }, [
                          _vm._v("兑换物品"),
                        ]),
                        _c("span", { staticClass: "confirm-value" }, [
                          _vm._v(
                            _vm._s(_vm.info.gift.name) +
                              "×" +
                              _vm._s(_vm.info.can_receive_num)
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c(
                    "van-button",
                    {
                      staticClass: "confirm-btn",
                      attrs: { round: "", block: "", color: "#ff8e38" },
                      on: { click: _vm.onConfirm },
                    },
                    [_vm._v("确认")]
                  ),
                  _c("div", { staticClass: "kefu", on: { click: _vm.kefu } }, [
                    _vm._v("联系客服"),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c("Dialog", { ref: "dialog" }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }